import React, { Component } from "react";
import Navbar from "../../Components/NavBar";

export default class Offers extends Component {
  state = {
    offers: [],
  };

  addOfferClick = event => {
    var newOffers = [...this.state.offers];
    newOffers.push("dup");
    this.setState({ offers: newOffers });

    console.log("add dup");
  };

  render() {
    console.log("redner!");
    return (
      <div>
        <Navbar items={["Offers", "Prices", "Contact"]} />
        <h1>Offers</h1>

        <button onClick={this.addOfferClick}>Add offer</button>

        <pre>{JSON.stringify(this.state, null, 2)}</pre>
        <ul>
          {this.state?.offers.map(x => (
            <li>{x}</li>
          ))}
        </ul>
      </div>
    );
  }
}
